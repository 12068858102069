import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { eachDayOfInterval, format, addDays } from 'date-fns';
import NiceModal from '@ebay/nice-modal-react';
import { merge, reduce } from 'lodash';
import { Redirect } from 'react-router-dom';
import { FEATURE_FLAG, SEGMENT_TYPE } from 'itrvl-types';

import ItineraryBuilder from './ItineraryBuilder';
import { useUser } from 'common/hooks/user';
import { uuidv4 } from 'common/helpers';
import { useBuilderStore } from './store';
import { useEffect } from 'react';
import { useApi } from 'common/hooks/api';
import { ownAccommodationProps } from './AccommodationsList';
import { formatDate } from './utils';
import { dateZero } from 'common/helpers/dates';

import logger from 'itrvl-logger';
const log = logger(__filename);
log.trace(__filename);

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

/**
 * This is mostly to wire up the different providers that belong to just the
 * ItineraryBuilder subtree.
 *
 * @todo: This can go away partially/completely depending on the adoption of the various libraries
 *
 * @param {*} props
 * @returns A react component tree
 */
const ItineraryBuilderEntry = props => {
  const setData = useBuilderStore(state => state.actions.copyToBuilder.setData);
  const clearUiDateMap = useBuilderStore(state => state.actions.copyToBuilder.clearUiDateMap);
  const setUiDateMap = useBuilderStore(state => state.actions.copyToBuilder.setUiDateMap);
  const setOpenChangeStartDate = useBuilderStore(state => state.actions.changeStartDate.setOpen);

  useEffect(() => {
    const itinerary = props?.location?.state?.itinerary;
    if (!itinerary) return;

    const openChangeStartDate = props?.location?.state?.openChangeStartDate;
    setOpenChangeStartDate(openChangeStartDate);

    window.history.replaceState({}, '', '/itinerary-builder'); // Comment for reload testing.

    const ui = {
      dateMap: [],
    };
    clearUiDateMap();
    const childrenAges = reduce(
      itinerary.itinerary.params.rooms,
      (ages, room) => {
        ages.push(...room.children);
        return ages;
      },
      [],
    );
    const data = {
      adults: itinerary.itinerary.adults,
      children: itinerary.itinerary.children,
      childrenAges,
      date: dateZero(itinerary.itinerary.startDate),
      pinnedAccommodationMap: {},
      pinAccommodations:
        itinerary.itinerary.supplierCodes ??
        reduce(
          itinerary.itinerary.segments,
          (supplierCodes, segment) => {
            supplierCodes.push(segment.supplierCode);
            return supplierCodes;
          },
          [],
        ), // Pin these on load. ?? Pin supplierCodes from erred quote.
      ownAccommodations: [], // Like pinAccommodations.
      //segments:[],
      segmentsById: {},
      segmentIds: [],
      segmentsOriginal: itinerary.itinerary.segments,
    };
    for (let segment of itinerary.itinerary.segments) {
      const uuid = uuidv4();

      if (segment.type !== SEGMENT_TYPE.STAY) {
        continue;
      }

      const rooms = [];
      for (let i = 0, len = segment.optionKeys.length; i < len; i++) {
        const adults = itinerary.itinerary.params.accommodationRooms[segment.supplierCode][i].adults;
        const children = itinerary.itinerary.params.accommodationRooms[segment.supplierCode][i].children.length;
        const room = {
          id: uuidv4(),
          room: {
            optionKey: segment.optionKeys[i],
            roomTypeId: segment.roomTypeIds[i],
            roomNameClient: segment.roomNamesClient?.[i], // Not set on OWN.
            uniqueName: segment.roomNames?.[i], // Not set on OWN.
            name: segment.roomNamesClient?.[i], // Not set on OWN.
            // TODO set these extra pieces post like `setCampSegment`?
            //"extraName": "Emperor Suite",
            //"comment": "Standard Rate",
            //"guestNormal": 4,
            //"normalAdults": 4,
            //"normalChildren": 3,
            //"description": "Emperor Suite",
            systemType: segment.systemTypes[i],
            //minPax: 1,
            //minChildAge: 0,
            //prov: 'K',
            //lastRateDate: '2025-04-30',
            cost: segment.sell / (adults + children), // Out of date pricing based on og quote.
          },
          adults,
          children,
        };
        rooms.push(room);
      }
      const segmentUi = {
        startDateString: segment.startDate,
        startDate: dateZero(segment.startDate),
        endDateString: formatDate(addDays(dateZero(segment.endDate), -1)),
        endDate: addDays(dateZero(segment.endDate), -1),
        id: uuid,
        supplierCode: segment.supplierCode,
        campName: segment.supplierName,
        // Camp info is set post-pin, in `setCampSegment`.
        rooms,
      };
      if (segment.arrangedBy === 'Agent') {
        // Own arranged.
        merge(segmentUi, ownAccommodationProps);

        segmentUi.title = segment.description;
        segmentUi.regionCode = segment.locationCode;

        data.ownAccommodations.push(segment.locationCode);
      }
      data.segmentIds.push(uuid);
      data.segmentsById[uuid] = segmentUi;

      const days = eachDayOfInterval({ start: segmentUi.startDate, end: segmentUi.endDate });
      for (let day of days) {
        ui.dateMap[format(day, 'yyyy-MM-dd')] = uuid;
      }
    }

    setUiDateMap(ui.dateMap);
    setData && setData(data);
  }, [
    props?.location?.state?.itinerary,
    props?.location?.state?.openChangeStartDate,
    setOpenChangeStartDate,
    setData,
    setUiDateMap,
    clearUiDateMap,
  ]);

  // need to inject Api so it's available in the store, for now
  // @todo: remove me once we refactor fetches
  const Api = useApi();
  const setApi = useBuilderStore(state => state.actions.api.setApi);
  useEffect(() => {
    setApi && Api && setApi(Api);
  }, [Api, setApi]);

  const user = useUser();

  // need to inject Feature flag for RestOfWorld
  const setFilter = useBuilderStore(state => state.actions.settings.setFilter);
  useEffect(() => {
    setFilter && setFilter(user?.agency?.[FEATURE_FLAG.REST_OF_WORLD] ? true : false);
  }, [user?.agency, setFilter]);

  // redirect if you don't have the flag
  // @todo: this can be removed once the feature is live
  if (user?.agency?.[FEATURE_FLAG.ITINERARY_BUILDER_2] !== true) {
    return <Redirect to="/" />;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <NiceModal.Provider>
        <ItineraryBuilder />
      </NiceModal.Provider>
    </QueryClientProvider>
  );
};

export default ItineraryBuilderEntry;

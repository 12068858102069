import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useApi } from 'common/hooks/api';
import { useSnackbar } from 'notistack';
import { handleError } from 'components/handleSnackBarMessages';
import Agency from 'models/Agency';

import logger from 'itrvl-logger';
const log = logger(__filename);

export const usePatchAgencyMutation = () => {
  const Api = useApi();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: async ({ agencyId, data }) => {
      try {
        return await Api.patchAgency(agencyId, data);
      } catch (err) {
        log.error('Patch agency failed', err);
        throw err;
      }
    },
    onSuccess: (_, { agencyId }) => {
      queryClient.invalidateQueries([agencyId, 'myAgencySettings']);
    },
    onError: err => {
      handleError(err, 'An error occurred while updating the agency.', enqueueSnackbar, log);
    },
  });
};

// * Used in My Agency
export const useUpdateAgency = () => {
  const Api = useApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ agencyId, agency }) => {
      await Api.patchAgency(agencyId, agency);
      const response = await Api.getAgency(agencyId);
      return new Agency(response?.data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['myAgencySettings']);
    },
    onError: err => {
      log.error('Agency update failed', err);
    },
  });
};
